import CandidateForm from "./components/candidateForm";



function App() {
  return (
    <div className="App">
      <CandidateForm />
    </div>
  );
}

export default App;
